import * as ct from '../../shared/ClientConstants';

export const SUPERADMIN_CONFIG = {
  path: 'user',
  allowedRoles: ['SuperAdmin'],
  name: 'user',
  uiName: 'User',
  reduceKey: 'name',
  noCreate: true,
  fields: [
    {
      key: 'roles',
      type: 'list',
      options: ct.USER_ROLES,
      obj2strFunc: (obj) => obj,
    },
  ],
};

export const ADMIN_CONFIG = {
  allowedRoles: ['SuperAdmin', 'Admin'],
};

export const DIRECTOR_CONFIG = {
  allowedRoles: ['SuperAdmin', 'Admin', 'Director'],
};

export const DIRECTOR_SERIES_CONFIG = {
  allowedRoles: ['SuperAdmin', 'Admin', 'Director'],
};

export const DELEGATE_CONFIG = {
  allowedRoles: ['SuperAdmin', 'Admin', 'Delegate'],
};

export const CRUD_CONFIGS = [
  {
    path: 'club',
    allowedRoles: ['SuperAdmin', 'Admin', 'Moderator-club'],
    name: 'club',
    uiName: 'Mod-Clubs',
    reduceKey: 'name',
    fields: [
      { key: 'name', type: 'string' },
      { key: 'urlName', type: 'string' },
      { key: 'avatar', type: 'avatar' },
      {
        key: 'countryCode',
        type: 'selection',
        options: ct.ALL_FLAG_CODES,
        flags: true,
      },
      { key: 'email', type: 'string' },
      { key: 'city', type: 'string' },
      { key: 'phone', type: 'string' },
      { key: 'facebookProfile', type: 'string' },
      { key: 'history', type: 'string' },
    ],
  },
  /*
  {
    path: 'document',
    allowedRoles: ['SuperAdmin', 'Admin', 'Moderator-document'],
    name: 'document',
    uiName: 'Mod-Documents',
    reduceKey: 'name',
    fields: [
      { key: 'name', type: 'string' },
      { key: 'tag', type: 'string' },
      { key: 'description', type: 'string' },
      { key: 'date', type: 'date' },
      {
        key: 'filepath',
        type: 'filepath',
        uploadLocation: 'document',
      },
    ],
  },
  {
    path: 'earticle',
    allowedRoles: ['SuperAdmin', 'Admin', 'Moderator-eArticle'],
    name: 'eArticle',
    uiName: 'Mod-eSchool-Articles',
    reduceKey: 'name',
    fields: [
      { key: 'name', type: 'string' },
      { key: 'author', type: 'string' },
      {
        key: 'level',
        type: 'selection',
        options: ct.ESCHOOL_LEVELS,
      },
      {
        key: 'imageFilepath',
        type: 'filepath',
        uploadLocation: 'eArticleImage',
      },
      {
        key: 'contentType',
        type: 'enum',
        dbValues: [1, 2, 3],
        uiValues: ['Link', 'File', 'Blob [Not Supported]'],
      },
      { key: 'link', type: 'string' },
      {
        key: 'contentsFilepath',
        type: 'filepath',
        uploadLocation: 'eArticleContents',
      },
    ],
  },
  {
    path: 'eposition',
    allowedRoles: ['SuperAdmin', 'Admin', 'Moderator-ePosition'],
    name: 'ePosition',
    uiName: 'Mod-eSchool-Positions',
    reduceKey: 'xgid',
    fields: [
      { key: 'xgid', type: 'string' },
      {
        key: 'type',
        type: 'selection',
        options: ct.EPOSITION_TYPES,
      },
      { key: 'author', type: 'string' },
      {
        key: 'level',
        type: 'selection',
        options: ct.ESCHOOL_LEVELS,
      },
      { key: 'question', type: 'string' },
      {
        key: 'concepts',
        type: 'list',
        options: ct.EPOSITION_CONCEPTS,
        obj2strFunc: (obj) => obj,
      },
      {
        key: 'questionImageFilepath',
        type: 'filepath',
        uploadLocation: 'ePositionQuestionImage',
      },
      {
        key: 'solutionImageFilepath',
        type: 'filepath',
        uploadLocation: 'ePositionSolutionImage',
      },
    ],
  },
  {
    path: 'evideo',
    allowedRoles: ['SuperAdmin', 'Admin', 'Moderator-eVideo'],
    name: 'eVideo',
    uiName: 'Mod-eSchool-Videos',
    reduceKey: 'name',
    fields: [
      { key: 'name', type: 'string' },
      { key: 'author', type: 'string' },
      {
        key: 'level',
        type: 'selection',
        options: ct.ESCHOOL_LEVELS,
      },
      { key: 'videoLink', type: 'string' },
    ],
  },
  */
  {
    path: 'marticle',
    allowedRoles: ['SuperAdmin', 'Admin', 'Moderator-mArticle'],
    name: 'mArticle',
    uiName: 'Mod-Media-Articles',
    reduceKey: 'name',
    fields: [
      { key: 'name', type: 'string' },
      { key: 'author', type: 'string' },
      { key: 'description', type: 'string' },
      { key: 'createdDate', type: 'date' },
      {
        key: 'imageFilepath',
        type: 'filepath',
        uploadLocation: 'mArticleImage',
      },
      {
        key: 'contentType',
        type: 'enum',
        dbValues: [1, 2, 3],
        uiValues: ['Link', 'File', 'Blob'],
      },
      { key: 'link', type: 'string' },
      {
        key: 'contentsFilepath',
        type: 'filepath',
        uploadLocation: 'mArticleContents',
      },
    ],
  },

  {
    path: 'mgallery',
    allowedRoles: ['SuperAdmin', 'Admin', 'Moderator-mGallery'],
    name: 'mGallery',
    uiName: 'Mod-Media-Galleries',
    reduceKey: 'name',
    fields: [
      { key: 'name', type: 'string' },
      { key: 'link', type: 'string' },
      { key: 'date', type: 'date' },
      {
        key: 'imageFilepath',
        type: 'filepath',
        uploadLocation: 'mGalleryImage',
      },
      { key: 'videoLinks', type: 'array' },
    ],
  },
  {
    path: 'partner',
    allowedRoles: ['SuperAdmin', 'Admin', 'Moderator-partner'],
    name: 'partner',
    uiName: 'Mod-Partners',
    reduceKey: 'name',
    fields: [
      { key: 'name', type: 'string' },
      { key: 'description', type: 'string' },
      { key: 'link', type: 'string' },
      { key: 'startDate', type: 'date' },
      { key: 'endDate', type: 'date' },
      {
        key: 'filepath',
        type: 'filepath',
        uploadLocation: 'partner',
      },
    ],
  } /*
  {
    path: 'product',
    allowedRoles: ['SuperAdmin', 'Admin', 'Moderator-product'],
    name: 'product',
    uiName: 'Mod-Markets',
    reduceKey: 'name',
    fields: [
      { key: 'name', type: 'string' },
      { key: 'price', type: 'string' },
      { key: 'previousPrice', type: 'string' },
      { key: 'createdAt', type: 'date ' },
      { key: 'contact', type: 'string' },
      { key: 'stock', type: 'integer' },
      {
        key: 'imageFilepath',
        type: 'filepath',
        uploadLocation: 'productImage',
      },
      {
        key: 'brochureFilepath',
        type: 'filepath',
        uploadLocation: 'productBrochure',
      },
    ],
  },
  {
    path: 'sponsor',
    allowedRoles: ['SuperAdmin', 'Admin', 'Moderator-sponsor'],
    name: 'sponsor',
    uiName: 'Mod-Sponsors',
    reduceKey: 'name',
    fields: [
      { key: 'name', type: 'string' },
      { key: 'description', type: 'string' },
      { key: 'link', type: 'string' },
      {
        key: 'filepath',
        type: 'filepath',
        uploadLocation: 'sponsor',
      },
    ],
  },
  */,
  ,
  {
    path: 'user',
    allowedRoles: ['SuperAdmin', 'Admin', 'Moderator-user'],
    name: 'user',
    uiName: 'Mod-Users',
    reduceKey: 'name',
    noCreate: true,
    noDelete: true,
    fields: [
      { key: 'accountApproved', type: 'fixed', value: true },
      { key: 'firstName', type: 'string' },
      { key: 'lastName', type: 'string' },
      //{ key: 'profileHidden', type: 'boolean' },
      { key: 'urlName', type: 'string' },
      { key: 'avatar', type: 'avatar' },
      {
        key: 'countryCode',
        type: 'selection',
        options: ct.ALL_FLAG_CODES,
        flags: true,
      },
      { key: 'email', type: 'string' },
      { key: 'emailConfirmed', type: 'boolean' },
      { key: 'membershipExpirationDate', type: 'date' },
      { key: 'orgStatus', type: 'string' },
      { key: 'orgRole', type: 'string' },
      { key: 'birthDate', type: 'date' },
      { key: 'city', type: 'string' },
      { key: 'phone', type: 'string' },
      { key: 'bmabTitle', type: 'selection', options: ct.ALL_BMAB_TITLES },
      { key: 'facebookProfile', type: 'string' },
      { key: 'nickHeroes', type: 'string' },
      { key: 'nickGalaxy', type: 'string' },
    ],
  },
];
