import React, { Component } from 'react';

export class SettingsPanel extends Component {
  closeRightSidebar() {
    document.querySelector('.right-sidebar').classList.remove('open');
  }

  render() {
    return (
      <div>
        {/* <div id="settings-trigger"><i className="ti-settings"></i></div> */}
        <div id="right-sidebar" className="settings-panel right-sidebar">
          <i
            className="settings-close ti-close"
            onClick={this.closeRightSidebar}
          ></i>
          <div className="row">
            <div className="col-lg-12"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default SettingsPanel;
