import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import * as cc from './ControlConfig';

import { GlobalContext } from '../shared/GlobalContext';

function publicMenu(isLoggedIn) {
  return [
    {
      path: 'home',
      icon: 'ti-home',
      nameLabelRo: 'Acasa',
      nameLabelEn: 'Home',
      elements: null,
    },
    /*
    {
      path: 'organization',
      icon: 'ti-settings',
      nameLabelRo: 'Organizare',
      nameLabelEn: 'Organization',
      elements: ['about', 'information'],
      elementLabelsRo: ['Despre Noi', 'Informatii'],
      elementLabelsEn: ['About Us', 'Information'],
    },
    */
    /* ENABLE LATER
    {
      path: 'about',
      icon: 'ti-settings',
      nameLabelRo: 'Despre Noi',
      nameLabelEn: 'About Us',
      elements: null,
    },
    {
      path: 'information',
      icon: 'ti-settings',
      nameLabelRo: 'Informatii',
      nameLabelEn: 'Information',
      elements: null,
    },
    */
    {
      path: 'account',
      icon: 'ti-key',
      nameLabelRo: 'Contul Meu',
      nameLabelEn: 'My Account',
      elements: isLoggedIn
        ? //? ['profile', 'delegate']
          ['profile']
            .concat(cc.CRUD_CONFIGS.map((config) => config.path))
            .concat([
              'tdirector',
              'sdirector',
              'admin',
              'superadmin',
              'register',
            ])
        : ['login', 'register'],
      elementLabelsRo: isLoggedIn
        ? //? ['Profilul Meu', 'Clubul Meu']
          ['Profilul Meu']
            .concat(cc.CRUD_CONFIGS.map((config) => config.uiName))
            .concat([
              'Director de Turnee',
              'Director de Serii',
              'Zona de Admin',
              'SuperAdmin',
              'Inregistrate Jucator Nou',
            ])
        : ['Login', 'Inregistrare'],
      elementLabelsEn: isLoggedIn
        ? //['My Profile', 'My Club']
          ['My Profile']
            .concat(cc.CRUD_CONFIGS.map((config) => config.uiName))
            .concat([
              'Tournament Director',
              'Series Director',
              'Admin',
              'SuperAdmin',
              'Register New Player',
            ])
        : ['Login', 'Register'],
      elementRoles: isLoggedIn
        ? //? [null, cc.DELEGATE_CONFIG.allowedRoles]
          [null]
            .concat(cc.CRUD_CONFIGS.map((config) => config.allowedRoles))
            .concat([
              cc.DIRECTOR_CONFIG.allowedRoles,
              cc.DIRECTOR_SERIES_CONFIG.allowedRoles,
              cc.ADMIN_CONFIG.allowedRoles,
              cc.SUPERADMIN_CONFIG.allowedRoles,
              cc.DIRECTOR_CONFIG.allowedRoles,
            ])
        : null, //: [null, null] //same effect
    },
    {
      path: 'members',
      icon: 'icon-people',
      nameLabelRo: 'Membri',
      nameLabelEn: 'Profiles',
      elements: ['players', 'clubs'],
      elementLabelsRo: ['Jucatori', 'Cluburi'],
      elementLabelsEn: ['Players', 'Clubs'],
    },
    {
      path: 'competitions',
      icon: 'icon-trophy',
      nameLabelRo: 'Competitii',
      nameLabelEn: 'Competitions',
      elements: ['schedule', 'tournaments', 'series'],
      elementLabelsRo: ['Program competitional', 'Turnee', 'Serii'],
      elementLabelsEn: ['Upcoming Schedule', 'Tournaments', 'Series'],
    },
    {
      path: 'statistics',
      icon: 'ti-bar-chart-alt',
      nameLabelRo: 'Statistici',
      nameLabelEn: 'Statistics',
      elements: ['players', 'clubs', 'tournaments', 'series', 'elo', 'pr'],
      elementLabelsRo: [
        'Palmares jucatori',
        'Palmares cluburi',
        'Clasamente turnee',
        'Clasamente serii',
        'Clasament ELO',
        'Clasament PR',
      ],
      elementLabelsEn: [
        'Player Trophys',
        'Club Trophys',
        'Tournament Standings',
        'Series Standings',
        'ELO Standings',
        'PR Standings',
      ],
    },
    /*
    {
      path: 'market',
      icon: 'ti-shopping-cart',
      nameLabelRo: 'Market',
      nameLabelEn: 'Market',
      elements: null,
    },
    {
      path: 'eschool',
      icon: 'icon-book-open',
      nameLabelRo: 'e-School',
      nameLabelEn: 'e-School',
      elements: ['positions', 'articles', 'videos'],
      elementLabelsRo: [
        'Pozitii de joc',
        'Articole de studiu',
        'Video de studiu',
      ],
      elementLabelsEn: ['Positions', 'Articles', 'Videos'],
    },*/

    {
      path: 'media',
      icon: 'ti-tablet',
      nameLabelRo: 'Media',
      nameLabelEn: 'Media',
      //elements: ['articles', 'gallery'],
      //elementLabelsRo: ['Articole', 'Galerie'],
      //elementLabelsEn: ['Articles', 'Gallery'],
      elements: ['gallery'],
      elementLabelsRo: ['Galerie'],
      elementLabelsEn: ['Gallery'],
      elementRoles: [['SuperAdmin']],
    },

    /*
  {
    path: 'contact',
    icon: 'ti-headphone-alt',
    nameLabelRo: 'Contact',
    nameLabelEn: 'Contact',
    elements: null,
  },
  */
  ];
}

class Sidebar extends Component {
  static contextType = GlobalContext;
  state = {};

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach((i) => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(this.state).forEach((i) => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: '/organization', state: 'organizationMenuOpen' },
      { path: '/account', state: 'accountMenuOpen' },
      { path: '/members', state: 'membersMenuOpen' },
      { path: '/competitions', state: 'competitionsMenuOpen' },
      { path: '/statistics', state: 'statisticsMenuOpen' },
      { path: '/eschool', state: 'eschoolMenuOpen' },
      { path: '/media', state: 'mediaMenuOpen' },
    ];

    dropdownPaths.forEach((obj) => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true });
      }
    });
  }

  render() {
    return (
      <nav className='sidebar sidebar-offcanvas' id='sidebar'>
        <ul className='nav'>
          {publicMenu(this.context.user != null).map((m, mIndex) =>
            m.elements
              ? (!m.elementRoles ||
                  m.elementRoles.every((roles) => !roles) ||
                  (this.context.user &&
                    m.elementRoles.some(
                      (roles) =>
                        !roles ||
                        roles.some((role) =>
                          this.context.user.roles.includes(role)
                        )
                    ))) && (
                  <li
                    key={mIndex}
                    className={
                      this.isPathActive('/' + m.path)
                        ? 'nav-item active'
                        : 'nav-item'
                    }
                  >
                    <div
                      className={
                        this.state[m.path + 'MenuOpen']
                          ? 'nav-link menu-expanded'
                          : 'nav-link'
                      }
                      onClick={() => this.toggleMenuState(m.path + 'MenuOpen')}
                      data-toggle='collapse'
                    >
                      <i className={`${m.icon} menu-icon`}></i>
                      <span className='menu-title'>{m.nameLabelEn}</span>
                      <i className='menu-arrow'></i>
                    </div>
                    <Collapse in={this.state[m.path + 'MenuOpen']}>
                      <ul className='nav flex-column sub-menu'>
                        {m.elements.map(
                          (e, eIndex) =>
                            (!m.elementRoles ||
                              !m.elementRoles[eIndex] ||
                              (this.context.user &&
                                m.elementRoles[eIndex].some((role) =>
                                  this.context.user.roles.includes(role)
                                ))) && (
                              <li key={eIndex} className='nav-item'>
                                {' '}
                                <Link
                                  className={
                                    this.isPathActive('/' + m.path + '/' + e)
                                      ? 'nav-link active'
                                      : 'nav-link'
                                  }
                                  to={'/' + m.path + '/' + e}
                                >
                                  {m.elementLabelsEn[eIndex]}
                                </Link>
                              </li>
                            )
                        )}
                      </ul>
                    </Collapse>
                  </li>
                )
              : (!m.roles ||
                  (this.context.user &&
                    m.roles.some((role) =>
                      this.context.user.roles.includes(role)
                    ))) && (
                  <li
                    key={mIndex}
                    className={
                      this.isPathActive('/' + m.path)
                        ? 'nav-item active'
                        : 'nav-item'
                    }
                  >
                    <Link className='nav-link' to={'/' + m.path}>
                      <i className={`${m.icon} menu-icon`}></i>
                      <span className='menu-title'>{m.nameLabelEn}</span>
                    </Link>
                  </li>
                )
          )}
        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {
      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }
}

export default withRouter(Sidebar);
