import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Editor } from '@tinymce/tinymce-react';

class AdvEditorModal extends React.Component {
  state = {
    contents: this.props.initialContents,
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      this.setState({ contents: this.props.initialContents });
    }
  }

  render() {
    return (
      <Modal
        size={this.props.size || 'lg'}
        show={this.props.show}
        onHide={() => this.props.onClose()}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Editor
            initialValue={this.props.initialContents || ''}
            init={{ height: 500 }}
            onChange={(event) =>
              this.setState({ contents: event.target.getContent() })
            }
          />
        </Modal.Body>

        <Modal.Footer>
          <Button variant="warning m-2" onClick={() => this.props.onClose()}>
            Close
          </Button>
          <Button
            variant="success m-2"
            onClick={() => this.props.onSave(this.state.contents)}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
export default AdvEditorModal;
