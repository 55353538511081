import React, { Component } from 'react';
import { Button, Tab, Tabs } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { GlobalContext } from './GlobalContext';
import * as utils from '../../shared/ClientUtils';
import PlayerId from '../common/PlayerId';
import TeamId from '../common/TeamId';
import ClubId from '../common/ClubId';

const MENU_STYLE = { width: '46px', height: '46px', margin: '0px' };

class Navbar extends Component {
  static contextType = GlobalContext;
  state = {
    searching: false,
    searchString: null,
    currentTime: Date.now(),
    refreshing: false,
  };

  toggleOffcanvas() {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }

  canSearch = () => {
    return !this.state.searching && this.state.searchString;
  };

  handleSearchClick = () => {
    if (!this.state.searchResults) {
      this.setState({ searching: true }, () => this.search());
    } else {
      this.setState({
        searchResults: null,
        searchString: null,
      });
    }
  };

  search() {
    utils.quickGet(
      '/api/data/table/search',
      { searchString: this.state.searchString.trim() },
      () =>
        this.setState(
          {
            searching: false,
            searchString: null,
          },
          () =>
            this.context.showModal(
              'No results',
              'No records were found to match your search string.'
            )
        ),
      null,
      (j) => {
        this.setState(
          {
            searching: false,
            searchString: null,
          },
          () =>
            this.context.showModal(
              'Search results',
              <Tabs>
                {j.users.length > 0 && (
                  <Tab eventKey={'users'} title={'Users'}>
                    {j.users.map((user) => (
                      <PlayerId key={user._id} {...user} />
                    ))}
                  </Tab>
                )}
                {j.teams.length > 0 && (
                  <Tab eventKey={'teams'} title={'Teams'}>
                    {j.teams.map((team) => (
                      <TeamId key={team._id} {...team} />
                    ))}
                  </Tab>
                )}
                {j.clubs.length > 0 && (
                  <Tab eventKey={'clubs'} title={'Clubs'}>
                    {j.clubs.map((club) => (
                      <ClubId key={club._id} {...club} />
                    ))}
                  </Tab>
                )}
                {j.tournaments.length > 0 && (
                  <Tab eventKey={'tournaments'} title={'Tournaments'}>
                    {j.tournaments.map((tournament) => (
                      <div>
                        <a href={'/tournament/' + tournament._id}>
                          {tournament.name}
                        </a>
                      </div>
                    ))}
                  </Tab>
                )}
                {j.series.length > 0 && (
                  <Tab eventKey={'series'} title={'Series'}>
                    {j.series.map((series) => (
                      <div>
                        <a href={'/series/' + series._id}>{series.name}</a>
                      </div>
                    ))}
                  </Tab>
                )}
              </Tabs>
            )
        );
      }
    );
  }

  refreshHomePage = () => {
    utils.quickPost(
      '/api/data/table/refreshHomeInfo',
      {},
      (title, message) => {
        utils.toastErrorFunc(title, message);
        this.setState({ refreshing: false });
      },
      'Error refreshing home page information: ',
      (j) => {
        utils.toastSuccessFunc(
          'SUCCESS',
          'Home page info updated. Please refresh the page.'
        );
        this.setState({ refreshing: false });
      }
    );
  };

  componentDidMount() {
    this.clockTimer = setInterval(
      () => this.setState({ currentTime: Date.now() }),
      1000
    );
  }

  componentWillUnmount() {
    clearInterval(this.clockTimer);
  }

  render() {
    return (
      <nav className='navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row'>
        <div
          className='text-center navbar-brand-wrapper d-flex align-items-center justify-content-center'
          style={{ backgroundColor: 'white' }}
        >
          <Link className='navbar-brand brand-logo mr-5' to='/'>
            <img
              src={'/logo.png'}
              className='mr-2'
              alt='logo'
              style={{ marginLeft: '5%' }}
            />
          </Link>
          <Link className='navbar-brand brand-logo-mini' to='/'>
            <img src={'../../logo.png'} alt='logo' />
          </Link>
        </div>
        <div className='navbar-menu-wrapper d-flex align-items-stretch justify-content-end'>
          <button
            className='navbar-toggler navbar-toggler align-self-center'
            type='button'
            onClick={() => document.body.classList.toggle('sidebar-icon-only')}
          >
            <img src={'/home/menu.png'} alt='menu' style={MENU_STYLE} />
          </button>
          <ul className='navbar-nav mr-lg-2'>
            <li className='nav-item nav-search d-block'>
              <div className='input-group'>
                <div
                  className='input-group-prepend hover-cursor'
                  id='navbar-search-icon'
                >
                  <span className='input-group-text' id='search'>
                    <i className='ti-search'></i>
                  </span>
                </div>
                <input
                  type='text'
                  className='form-control'
                  id='navbar-search-input'
                  placeholder='Search'
                  aria-label='search'
                  aria-describedby='search'
                  value={this.state.searchString || ''}
                  onChange={(event) =>
                    this.setState({ searchString: event.target.value })
                  }
                  onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                      if (this.canSearch()) {
                        this.handleSearchClick();
                      }
                    }
                  }}
                  disabled={this.state.searchResults}
                ></input>
                <div
                  className='input-group-append hover-cursor'
                  id='navbar-search-icon'
                >
                  <button
                    disabled={!this.canSearch()}
                    className='btn btn-primary'
                    onClick={this.handleSearchClick}
                    style={{
                      background: '#bf9000',
                      color: 'black',
                      border: '#bf9000',
                      marginLeft: '5px',
                    }}
                  >
                    {this.searching ? '...' : <i className='ti-search'></i>}
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <ul className='navbar-nav navbar-nav-right'>
            {this.context.user &&
              this.context.user.roles.includes('SuperAdmin') && (
                <button
                  className='btn btn-primary'
                  disabled={this.state.refreshing}
                  style={{
                    border: '#bf9000',
                    marginRight: 10,
                  }}
                  onClick={(event) => {
                    event.preventDefault();
                    if (window.confirm('Refresh home page information?')) {
                      this.setState({ refreshing: true }, this.refreshHomePage);
                    }
                  }}
                >
                  HR
                </button>
              )}
            {this.context.user &&
              this.context.user.roles.includes('SuperAdmin') && (
                <button
                  className='btn btn-primary'
                  style={{
                    background: this.context.directorView
                      ? this.context.directorView.adminView
                        ? 'red'
                        : 'green'
                      : 'grey',
                    color: 'white',
                    border: '#bf9000',
                    marginRight: 10,
                  }}
                  onClick={(event) => {
                    event.preventDefault();
                    this.context.toggleDirectorView();
                  }}
                >
                  {this.context.directorView &&
                  this.context.directorView.adminView
                    ? 'A'
                    : 'D'}
                </button>
              )}
            <li>
              <div style={{ fontWeight: 'bold', color: '#bf9000' }}>
                {new Date(this.state.currentTime).toLocaleDateString(
                  undefined,
                  { month: 'short', day: 'numeric' }
                )}
              </div>
              <div style={{ fontWeight: 'bold', color: '#bf9000' }}>
                {new Date(this.state.currentTime).toLocaleTimeString(
                  undefined,
                  { hour: '2-digit', minute: '2-digit' }
                )}
              </div>
            </li>
            {this.context.user && this.context.user.proCode ? (
              <li className='nav-item nav-profile'>
                <PlayerId {...this.context.user} />
              </li>
            ) : (
              <li className='nav-item nav-profile'>
                <PlayerId name='Login' proCode='none' _id='login' />
              </li>
            )}
            {this.context.user && (
              <button
                className='btn btn-primary'
                style={{
                  background: '#bf9000',
                  color: 'black',
                  border: '#bf9000',
                }}
                onClick={(event) => {
                  event.preventDefault();
                  this.context.signOutFunc();
                }}
              >
                Sign Out
              </button>
            )}
          </ul>
          <button
            className='navbar-toggler navbar-toggler-right d-lg-none'
            type='button'
            onClick={this.toggleOffcanvas}
          >
            <img src={'../../home/menu.png'} alt='menu' style={MENU_STYLE} />
          </button>
        </div>
      </nav>
    );
  }
}

export default Navbar;
