import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
//import { Document, Page } from 'react-pdf';
import AdvDownloadLink from '../common/AdvDownloadLink';

class AdvPdfModal extends React.Component {
  state = {
    currentPage: 1,
    numPages: 1,
  };
  navigation = (index) => (
    <div key={index}>
      <button
        className="btn btn-primary"
        disabled={this.state.currentPage === 1}
        onClick={(event) => {
          event.preventDefault();
          let currentPage = this.state.currentPage - 1;
          if (currentPage < 1) {
            currentPage = 1;
          }
          this.setState({ currentPage });
        }}
      >
        Previous
      </button>
      {` Page ${this.state.currentPage} of ${this.state.numPages} `}
      <button
        className="btn btn-primary"
        disabled={this.state.currentPage === this.state.numPages}
        onClick={(event) => {
          event.preventDefault();
          let currentPage = this.state.currentPage + 1;
          if (currentPage > this.state.numPages) {
            currentPage = this.state.numPages;
          }
          this.setState({ currentPage });
        }}
      >
        Next
      </button>
      <AdvDownloadLink asButton text="Download" link={this.props.link} />
    </div>
  );
  render() {
    return (
      <Modal
        size={this.props.size || 'lg'}
        show={this.props.show}
        onHide={() => this.props.onClose && this.props.onClose()}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>PDF Viewer</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {this.navigation(1)}
          <div>
            <Document
              file={this.props.link}
              onLoadSuccess={({ numPages }) =>
                this.setState({ numPages, currentPage: 1 })
              }
            >
              <Page width="1000" pageNumber={this.state.currentPage} />
            </Document>
          </div>
          {this.navigation(2)}
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="warning m-2"
            onClick={() => this.props.onClose && this.props.onClose()}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
export default AdvPdfModal;
