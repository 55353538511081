import React from 'react';
import * as utils from '../../shared/ClientUtils';

class AdvFilepathInput extends React.Component {
  state = {
    selectedFile: null,
  };

  render() {
    return (
      <div>
        <h3 className='card-title'>{this.props.label}</h3>
        <div>
          {!this.props.writeOnly &&
            (this.props.initialValue ? (
              <a
                href='#'
                onClick={() => {
                  utils.fileDownload(
                    '/api/file/path?path=' + this.props.initialValue,
                    this.props.alertFunc,
                    'ERROR downloading file: ',
                    this.props.downloadedFileName
                  );
                }}
              >
                Download
              </a>
            ) : (
              'No file uploaded.'
            ))}
        </div>
        <div>
          <input
            type='file'
            name='Upload new file'
            onChange={(event) =>
              this.setState({ selectedFile: event.target.files[0] })
            }
          />
        </div>
        <div>
          <button
            className='btn btn-primary'
            disabled={!this.state.selectedFile}
            onClick={(e) => {
              e.preventDefault();
              utils.fileUpload(
                '/api/file/upload/' + this.props.uploadLocation,
                { id: this.props.id },
                this.state.selectedFile,
                this.props.alertFunc,
                'Error uploading file: ',
                this.props.onUploadComplete
              );
            }}
          >
            Upload
          </button>
        </div>
      </div>
    );
  }
}
export default AdvFilepathInput;
