import React from 'react';
import { Modal, Button } from 'react-bootstrap';

class AdvModal extends React.Component {
  render() {
    return (
      /*
      <Modal isOpen className={this.props.className}>
        <ModalHeader toggle={this.props.onClose}>
          {this.props.title}
        </ModalHeader>
        <ModalBody>{this.props.body}</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.props.onOk}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
*/

      <Modal
        size={this.props.size || 'lg'}
        show={this.props.show}
        onHide={() => this.props.onClose()}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>{this.props.body}</Modal.Body>

        <Modal.Footer>
          <Button variant="success m-2" onClick={() => this.props.onClose()}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
export default AdvModal;
