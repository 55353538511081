//export const API_URL_BASE = 'http://api.probg.org';
export const API_URL_BASE = '';

export const TOAST_OPTIONS = {
  hideAfter: 5,
  position: 'top-right',
  heading: 'Alert',
};

export const TOURNAMENT_STATUS_CREATED = 1;
export const TOURNAMENT_STATUS_REGISTRATION_OPEN = 2;
export const TOURNAMENT_STATUS_REGISTRATION_CLOSED = 3;
export const TOURNAMENT_STATUS_READY = 4;
export const TOURNAMENT_STATUS_IN_PROGRESS = 5;
export const TOURNAMENT_STATUS_COMPLETED = 6;

/* Tournament system ID */
export const TOURNAMENT_SYSTEM_LEAGUE = 1;
export const TOURNAMENT_SYSTEM_KO = 2;
export const TOURNAMENT_SYSTEM_CUP = 3;
export const TOURNAMENT_SYSTEM_SWISS = 4;
export const TOURNAMENT_SYSTEM_RKO = 5;
export const TOURNAMENT_SYSTEM_DOUBLES = 6;

export const SERIES_STATUS_CREATED = 1;
export const SERIES_STATUS_IN_PROGRESS = 5;
export const SERIES_STATUS_COMPLETED = 6;

export const SERIES_TYPE_INDIVIDUALS = 1;
export const SERIES_TYPE_TEAMS = 2;
export const SERIES_TYPE_CLUBS = 3;

export const ARTICLE_CONTENT_TYPE_LINK = 1;
export const ARTICLE_CONTENT_TYPE_FILE = 2;
export const ARTICLE_CONTENT_TYPE_BLOB = 3;

export const ALL_SERIES_TYPES = ['Individuals', 'Teams', 'Clubs'];
export const ALL_SERIES_STATUS_OPTIONS = ['Created', 'Running', 'Completed'];

export const ALL_TOURNAMENT_STATUS_STRINGS = [
  'Created',
  'Registration Open',
  'Registration Closed',
  'Ready to Start',
  'In Progress',
  'Complete',
];
export const ALL_TOURNAMENT_STATUS_MESSAGES = [
  'Tournament has just been created. You can edit tournament settings, then proceed to registration.',
  'Registration is open. You can add or remove participants and participants may self-register. You can still edit tournament settings.',
  'Registration is closed. You can still add or remove participants, edit tournament settings, or go back to registration open. When you are absolutely certain that all settings are complete, you can proceed to the ready state.',
  'Tournament is ready to begin. No further edits can be done to tournament settings or participants. You can start the tournament.',
  'Tournament is running. You can edit details of the current round and proceed to next round when all match results have been filled in. You can mark the tournament as complete when all rounds have finished.',
  'Tournament has been completed. You cannot make any further changes.',
];

export const ALL_TOURNAMENT_SYSTEMS = [
  'League',
  'KO',
  'Cup',
  'Swiss',
  'Rebuy-KO',
  'Doubles',
];
export const ALL_TEAM_MATCHUP_METHODS = ['All-vs-All'];
export const ALL_SCORING_SYSTEMS = [
  'Victory Only 1p',
  'Victory 1p + (10-PR)/10',
  'Victory 1p + (10-PR)/15',
  'Victory 1p + (10-PR)/20',
  'Victory 1p + Best PR 1p',
  'Win & Skill',
  'Best PR Only 1p',
];

export const ALL_SWISS_BYE_ALLOCATIONS = [
  'Last Position in Table',
  'Random Bottom',
  'Random All',
  'Random Top',
];
export const ALL_SWISS_PAIRING_PRIORITIES = [
  'Minimize Repeated Matchups (the number of times same participants meet in the tournament)',
  'Pair Participants with Same Number of Lives (Same Losses)',
];

export const ALL_KO_FIRST_PHASES = [1, 2, 4, 8, 16, 32, 64, 128, 256, 512];
export const ALL_KO_FIRST_PHASES_LABELS = [
  '1/1 ==> 2 participants',
  '1/2 ==> 3 - 4 participants',
  '1/4 ==> 5 - 8 participants',
  '1/8 ==> 9 - 16 participants',
  '1/16 ==> 17 - 32 participants',
  '1/32 ==> 33 - 64 participants',
  '1/64 ==> 65 - 128 participants',
  '1/128 ==> 129 - 256 participants',
  '1/256 ==> 257 - 512 participants',
  '1/512 ==> 513 - 1024 participants',
];

export const EPOSITION_TYPES = ['checker', 'cube'];
export const EPOSITION_CONCEPTS = [
  'Race',
  'Containment',
  'Prime',
  'Blitz',
  'Opening',
];
export const ESCHOOL_LEVELS = ['Beginner', 'Intermediate', 'Advanced'];
export const USER_ROLES = [
  'SuperAdmin',
  'Admin',
  'Director',
  'Delegate',
  'Captain',
  'Moderator-club',
  'Moderator-document',
  'Moderator-eArticle',
  'Moderator-ePosition',
  'Moderator-eVideo',
  'Moderator-mArticle',
  'Moderator-mGallery',
  'Moderator-partner',
  'Moderator-product',
  'Moderator-sponsor',
  'Moderator-user',
  'ePosition-Comentator',
  'AccountCreator',
];

export function convertSeriesStatusInt2Str(statusInt) {
  switch (statusInt) {
    case 1:
      return ALL_SERIES_STATUS_OPTIONS[0];
    case 5:
      return ALL_SERIES_STATUS_OPTIONS[1];
    case 6:
      return ALL_SERIES_STATUS_OPTIONS[2];
    default:
      return 'Invalid Status: ' + statusInt;
  }
}

export function convertSeriesStatusStr2Int(statusStr) {
  switch (statusStr) {
    case ALL_SERIES_STATUS_OPTIONS[0]:
      return 1;
    case ALL_SERIES_STATUS_OPTIONS[1]:
      return 5;
    case ALL_SERIES_STATUS_OPTIONS[2]:
      return 6;
    default:
      return -1;
  }
}

export const ALL_PRIMARY_STATS = [
  'equity',
  'loseBg',
  'loseGmn',
  'loseSg',
  'winSg',
  'winGmn',
  'winBg',
];

export const ALL_ROLLS = [
  '11',
  '21',
  '22',
  '31',
  '32',
  '33',
  '41',
  '42',
  '43',
  '44',
  '51',
  '52',
  '53',
  '54',
  '55',
  '61',
  '62',
  '63',
  '64',
  '65',
  '66',
];

export const ALL_BMAB_TITLES = [
  'M3',
  'M2',
  'M1',
  'G3',
  'G2',
  'G1',
  'G0',
  'S3',
  'S2',
  'S1',
];

export const ALL_FLAG_CODES = [
  'AD',
  'AE',
  'AF',
  'AG',
  'AU',
  'AL',
  'AM',
  'AO',
  'AQ',
  'AR',
  'AS',
  'AT',
  'AU',
  'AW',
  'AX',
  'AZ',
  'BA',
  'BB',
  'BD',
  'BE',
  'BF',
  'BG',
  'BH',
  'BI',
  'BJ',
  'BL',
  'BM',
  'BN',
  'BO',
  'BQ',
  'BR',
  'BS',
  'BT',
  'BV',
  'BW',
  'BY',
  'BZ',
  'CA',
  'CC',
  'CD',
  'CF',
  'CG',
  'CH',
  'CI',
  'CK',
  'CL',
  'CM',
  'CN',
  'CO',
  'CR',
  'CU',
  'CV',
  'CW',
  'CX',
  'CY',
  'CZ',
  'DE',
  'DJ',
  'DK',
  'DM',
  'DO',
  'DZ',
  'EC',
  'EE',
  'EG',
  'EH',
  'ER',
  'ES',
  'ET',
  'FI',
  'FJ',
  'FK',
  'FM',
  'FO',
  'FR',
  'GA',
  'GB',
  'GD',
  'GE',
  'GF',
  'GG',
  'GH',
  'GI',
  'GL',
  'GM',
  'GN',
  'GP',
  'GQ',
  'GR',
  'GS',
  'GT',
  'GU',
  'GW',
  'GY',
  'HK',
  'HM',
  'HN',
  'HR',
  'HT',
  'HU',
  'ID',
  'IE',
  'IL',
  'IM',
  'IN',
  'IO',
  'IQ',
  'IR',
  'IS',
  'IT',
  'JE',
  'JM',
  'JO',
  'JP',
  'KE',
  'KG',
  'KH',
  'KI',
  'KM',
  'KN',
  'KP',
  'KR',
  'KW',
  'KY',
  'KZ',
  'LA',
  'LB',
  'LC',
  'LI',
  'LK',
  'LR',
  'LS',
  'LT',
  'LU',
  'LV',
  'LY',
  'MA',
  'MC',
  'MD',
  'ME',
  'MF',
  'MG',
  'MH',
  'MK',
  'ML',
  'MM',
  'MN',
  'MO',
  'MP',
  'MQ',
  'MR',
  'MS',
  'MT',
  'MU',
  'MV',
  'MW',
  'MX',
  'MY',
  'MZ',
  'NA',
  'NC',
  'NE',
  'NF',
  'NG',
  'NI',
  'NL',
  'NO',
  'NP',
  'NR',
  'NU',
  'NZ',
  'OM',
  'PA',
  'PE',
  'PF',
  'PG',
  'PH',
  'PK',
  'PL',
  'PM',
  'PN',
  'PR',
  'PS',
  'PT',
  'PW',
  'PY',
  'QA',
  'RE',
  'RO',
  'RS',
  'RU',
  'RW',
  'SA',
  'SB',
  'SC',
  'SD',
  'SE',
  'SG',
  'SH',
  'SI',
  'SJ',
  'SK',
  'SL',
  'SM',
  'SN',
  'SO',
  'SR',
  'SS',
  'ST',
  'SV',
  'SX',
  'SY',
  'SZ',
  'TC',
  'TD',
  'TF',
  'TG',
  'TH',
  'TJ',
  'TK',
  'TL',
  'TM',
  'TN',
  'TO',
  'TR',
  'TT',
  'TV',
  'TW',
  'TZ',
  'UA',
  'UG',
  'UM',
  'US',
  'UY',
  'UZ',
  'VA',
  'VC',
  'VE',
  'VG',
  'VI',
  'VN',
  'VU',
  'WF',
  'WS',
  'YE',
  'YT',
  'ZA',
  'ZM',
  'ZW',
];
