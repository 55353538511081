import React, { Component } from 'react';
import * as utils from '../../shared/ClientUtils';

import { GlobalContext } from '../shared/GlobalContext';

export class AdvDownloadLink extends Component {
  static contextType = GlobalContext;

  render() {
    return this.props.link ? (
      this.props.asButton ? (
        <button
          className='btn btn-warning'
          onClick={(event) => {
            event.preventDefault();
            utils.fileDownload(
              this.props.link,
              this.props.alertFunc,
              'ERROR downloading file: ',
              this.props.downloadedFileName
            );
          }}
        >
          {this.props.text}
        </button>
      ) : (
        <a
          href='#'
          onClick={() => {
            if (
              !this.props.membersOnly ||
              (this.context.user && utils.isActiveMember(this.context.user))
            ) {
              utils.fileDownload(
                this.props.downloadLink || this.props.link,
                this.props.alertFunc,
                'ERROR downloading file: ',
                this.props.downloadedFileName
              );
            } else {
              utils.showMembersOnlyToast();
            }
          }}
        >
          {this.props.text || <i className={this.props.iconClassName}></i>}
        </a>
      )
    ) : (
      this.props.text || <i className={this.props.iconClassName}></i>
    );
  }
}

export default AdvDownloadLink;
