import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class LockScreen extends Component {
  state = {};

  render() {
    return (
      <div>
        <div className="content-wrapper d-flex align-items-center auth h-100">
          <div className="row w-100 align-items-center">
            <div className="col-lg-4 mx-auto">
              <div className="auth-form-transparent text-left p-5 text-center">
                <form className="pt-5">
                  <div className="form-group">
                    <label>Password to unlock</label>
                    <input
                      type="password"
                      className="form-control text-center"
                      id="examplePassword1"
                      placeholder="Password"
                      onChange={(event) =>
                        this.setState({ password: event.target.value })
                      }
                    />
                  </div>
                  <div className="mt-5">
                    <button
                      className="btn btn-block btn-success btn-lg font-weight-medium"
                      onClick={() => {
                        if (this.state.password === 'maestru1') {
                          localStorage.setItem('unlocked', '1');
                          this.props.history.push('../home');
                        }
                      }}
                    >
                      Unlock
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LockScreen;
