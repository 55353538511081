import React, { Component } from 'react';
import * as ct from '../../shared/ClientConstants';

export class TeamId extends Component {
  render() {
    return (
      <div>
        {this.props.proCode && (
          <img
            src={
              ct.API_URL_BASE + '/api/file/avatar?proCode=' + this.props.proCode
            }
            alt="teamAvatar"
            className="img-sm rounded-circle"
            loading="lazy"
          />
        )}
        {this.props.countryCode && (
          <i
            className={`flag-icon flag-icon-${this.props.countryCode.toLowerCase()}`}
          />
        )}
        {this.props._id ? (
          <a href={'/club/' + this.props._id}> {this.props.name}</a>
        ) : (
          this.props.name
        )}
      </div>
    );
  }
}

export default TeamId;
