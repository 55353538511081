import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './App.scss';
import AppRoutes from './AppRoutes';
import Navbar from './shared/Navbar';
import Sidebar from './shared/Sidebar';
import SettingsPanel from './shared/SettingsPanel';
import Footer from './shared/Footer';
import AdvModal from './common/AdvModal';
import AdvEditorModal from './common/AdvEditorModal';
import { GlobalContext } from './shared/GlobalContext';
import * as utils from '../shared/ClientUtils';
import AdvPdfModal from './common/AdvPdfModal';
import AdvFilepathInput from './common/AdvFilepathInput';

class App extends Component {
  state = {
    user: null,
    directorView: null,
    modalContents: null,
    modalTitle: '',
    modalSize: null,
    showEditor: false,
    editorInitialContents: null,
    editorOnSave: null,
    showPdf: false,
    pdfLink: null,
  };

  showModal = (modalTitle, modalContents, modalSize) => {
    this.setState({ modalContents, modalTitle, modalSize });
  };

  showImageModal = (modalTitle, imageSrc, size) => {
    this.showModal(
      modalTitle,
      <img src={imageSrc} style={{ width: '100%' }} />,
      size || 'md'
    );
  };

  showMultipleImageModal = (
    modalTitle,
    imageSrcArr,
    initialIndex,
    onDelete,
    onAdd,
    size
  ) => {
    this.showModal(
      modalTitle,
      <MultipleImageModal
        imageSrcArr={imageSrcArr}
        initialIndex={initialIndex}
        onDelete={onDelete}
        onAdd={onAdd}
      />,
      size || 'md'
    );
  };

  showEditorModal = (initialContents, onSave) => {
    this.setState({
      showEditor: true,
      editorInitialContents: initialContents,
      editorOnSave: onSave,
    });
  };

  showPdfModal = (link) => {
    this.setState({
      showPdf: true,
      pdfLink: link,
    });
  };

  signInFunc = (user, token) => {
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('token', token);
    this.setState({ user });
  };
  signOutFunc = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    this.setState({ user: null });
  };

  signInStateChanged = () =>
    this.setState({
      user: JSON.parse(localStorage.getItem('user')),
    });

  toggleDirectorView = () => {
    if (!this.state.directorView) {
      this.setState({ directorView: { adminView: false } });
    } else {
      if (!this.state.directorView.adminView) {
        this.setState({ directorView: { adminView: true } });
      } else {
        this.setState({ directorView: null });
      }
    }
  };

  componentDidMount() {
    utils.setApiKey('fd74b8736d837h6d8746378h687x36487');
    this.setState({ user: this.props.user }, this.checkMembership);
    this.onRouteChanged();
  }

  checkMembership = () => {
    if (!this.state.user) {
      return;
    }

    if (!utils.isActiveMember(this.state.user)) {
      this.forceLogout();
      return;
    }

    utils.quickGet(
      '/api/data/user/one',
      { userId: this.state.user._id },
      utils.toastAlertFunc,
      'Error retrieving user information: ',
      (j) => {
        if (
          j.user.membershipExpirationDate !==
          this.state.user.membershipExpirationDate
        ) {
          this.forceLogout();
        } else {
          //TODO override user data to make sure it's up to date
        }
      }
    );
  };

  forceLogout = () => {
    utils.toastAlertFunc(
      'MEMBERSHIP CHANGED',
      'You have been automatically logged out due to changes in your membership. Please log in again.'
    );
    this.signOutFunc();
    this.props.history.push('/account/login');
  };

  render() {
    let navbarComponent = !this.state.isFullPageLayout ? <Navbar /> : '';
    let sidebarComponent = !this.state.isFullPageLayout ? <Sidebar /> : '';
    let SettingsPanelComponent = !this.state.isFullPageLayout ? (
      <SettingsPanel />
    ) : (
      ''
    );
    let footerComponent = !this.state.isFullPageLayout ? <Footer /> : '';
    return (
      <GlobalContext.Provider
        value={{
          user: this.state.user,
          signInFunc: this.signInFunc,
          signOutFunc: this.signOutFunc,
          showModal: this.showModal,
          showImageModal: this.showImageModal,
          showMultipleImageModal: this.showMultipleImageModal,
          showEditorModal: this.showEditorModal,
          showPdfModal: this.showPdfModal,
          checkMembership: this.checkMembership,
          directorView: this.state.directorView,
          toggleDirectorView: this.toggleDirectorView,
        }}
      >
        <div className='container-scroller'>
          <AdvModal
            show={this.state.modalContents != null}
            title={this.state.modalTitle}
            size={this.state.modalSize}
            onClose={() =>
              this.setState({
                modalContents: null,
                modalTitle: '',
                modalSize: null,
              })
            }
            body={this.state.modalContents}
          />
          <AdvEditorModal
            show={this.state.showEditor}
            title='Edit'
            size='lg'
            onClose={() =>
              this.setState({
                showEditor: false,
                editorInitialContents: null,
                editorOnSave: null,
              })
            }
            initialContents={this.state.editorInitialContents || ''}
            onSave={(newContents) => {
              this.state.editorOnSave(newContents);
            }}
          />
          <AdvPdfModal
            show={this.state.showPdf}
            link={this.state.pdfLink}
            onClose={() => this.setState({ showPdf: false, pdfLink: null })}
          />
          {navbarComponent}
          <div className='container-fluid page-body-wrapper'>
            {sidebarComponent}
            <div className='main-panel'>
              <div className='content-wrapper'>
                <AppRoutes lock={false} />
                {SettingsPanelComponent}
              </div>
              {footerComponent}
            </div>
          </div>
        </div>
      </GlobalContext.Provider>
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    window.scrollTo(0, 0);
    const fullPageLayoutRoutes = ['/lockscreen'];
    for (let i = 0; i < fullPageLayoutRoutes.length; i++) {
      if (this.props.location.pathname === fullPageLayoutRoutes[i]) {
        this.setState({
          isFullPageLayout: true,
        });
        document
          .querySelector('.page-body-wrapper')
          .classList.add('full-page-wrapper');
        break;
      } else {
        this.setState({
          isFullPageLayout: false,
        });
        document
          .querySelector('.page-body-wrapper')
          .classList.remove('full-page-wrapper');
      }
    }
  }
}

class MultipleImageModal extends Component {
  state = {
    index: this.props.initialIndex,
    imageSrcArr: this.props.imageSrcArr,
  };

  render() {
    return (
      <div>
        <div className='row'>
          <div className='col-3'>
            <button
              className='btn btn-primary'
              style={{ width: '100%' }}
              disabled={this.state.index === 0}
              onClick={(event) => {
                event.preventDefault();
                if (this.state.index > 0) {
                  this.setState({ index: this.state.index - 1 });
                }
              }}
            >
              {'Previous'}
            </button>
          </div>
          <div className='col-2'>
            {this.props.onDelete && (
              <button
                className='btn btn-danger'
                style={{ width: '100%' }}
                onClick={(event) => {
                  event.preventDefault();
                  this.props.onDelete(this.state.index);
                }}
              >
                {'Delete'}
              </button>
            )}
          </div>
          <div className='col-2'>
            <h4 style={{ width: '100%', textAlign: 'center' }}>
              {this.state.index + 1 + '/' + this.state.imageSrcArr.length}
            </h4>
          </div>
          <div className='col-2'>
            {this.props.onAdd && (
              <div>
                <button
                  className='btn btn-success'
                  disabled={!this.state.addFilepath}
                  style={{ width: '100%' }}
                  onClick={(event) => {
                    event.preventDefault();
                    console.log('Filepath: ', this.state.addFilepath);
                    this.props.onAdd(this.state.addFilepath);
                  }}
                >
                  {'Add'}
                </button>
                <input
                  type='file'
                  name='Upload new file'
                  onChange={(event) =>
                    this.setState({ addFilepath: event.target.files[0] })
                  }
                />
              </div>
            )}
          </div>
          <div className='col-3'>
            <button
              className='btn btn-primary'
              style={{ width: '100%' }}
              disabled={this.state.index === this.state.imageSrcArr.length - 1}
              onClick={(event) => {
                event.preventDefault();
                if (this.state.index < this.state.imageSrcArr.length - 1) {
                  this.setState({ index: this.state.index + 1 });
                }
              }}
            >
              {'Next'}
            </button>
          </div>
        </div>

        <div className='row'>
          <div className='col-12'>
            <img
              src={this.state.imageSrcArr[this.state.index]}
              style={{ width: '100%' }}
              loading='lazy'
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(App);
