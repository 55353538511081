import React, { Component } from 'react';
import AdvModal from '../common/AdvModal';
import { GlobalContext } from '../shared/GlobalContext';

class Footer extends Component {
  static contextType = GlobalContext;
  state = { solutionModal: null, modalTitle: null };

  render() {
    return (
      <footer className='footer'>
        <AdvModal
          show={this.state.solutionModal}
          title={this.state.modalTitle}
          size='md'
          onClose={() => this.setState({ solutionModal: null })}
          body={this.state.solutionModal}
        />
        <div className='d-sm-flex justify-content-center justify-content-sm-between'>
          <span className='text-muted text-center text-sm-left d-block d-sm-inline-block'>
            Copyright © 2024 - Pro Backgammon. All rights reserved.
          </span>
          <span className='text-muted text-center text-sm-left d-block d-sm-inline-block'>
            <a
              href='/terms/Terms.pdf'
              target='_blank'
              rel='noopener noreferrer'
            >
              Terms and Conditions
            </a>
          </span>
          <span className='text-muted text-center text-sm-left d-block d-sm-inline-block'>
            <a
              href='/terms/Confidentiality.pdf'
              target='_blank'
              rel='noopener noreferrer'
            >
              Confidentiality
            </a>
          </span>
          {/*
          <span className='text-muted text-center text-sm-left d-block d-sm-inline-block'>
            <a
              href='/terms/GDPR.pdf'
              target='_blank'
              rel='noopener noreferrer'
            >
              GDPR
            </a>
          </span>
    */}
          <span className='text-muted text-center text-sm-left d-block d-sm-inline-block'>
            <a
              href='https://www.anpc.ro'
              target='_blank'
              rel='noopener noreferrer'
            >
              ANPC
            </a>
          </span>
          <span className='text-muted text-center text-sm-left d-block d-sm-inline-block'>
            <a
              href='#'
              onClick={(event) => {
                event.preventDefault();
                this.setState({
                  solutionModal: (
                    <div>
                      <h3>Need help or assistance?</h3>
                      <p>Please reach out to us over email:</p>
                      <h4>contact@probg.org</h4>
                    </div>
                  ),
                  modalTitle: 'Contact Us',
                });
              }}
            >
              Contact Us
            </a>
          </span>
          <span className='text-muted float-none float-sm-right d-block mt-1 mt-sm-0 text-center'>
            We <i className='ti-heart text-danger'></i> backgammon!
          </span>
        </div>
      </footer>
    );
  }
}

export default Footer;
