import React, { Component, lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Spinner from '../app/shared/Spinner';
import LockScreen from './home/Lockscreen';
import { GlobalContext } from './shared/GlobalContext';

import * as cc from './shared/ControlConfig';
//import { isMobile } from 'react-device-detect';

const PlayerProfilePage = lazy(() => import('./user/PlayerProfilePage'));
const ClubProfilePage = lazy(() => import('./club/ClubProfilePage'));
const SeriesInfoPage = lazy(() => import('./series/SeriesInfoPage'));
const TournamentInfoPage = lazy(() =>
  import('./tournament/TournamentInfoPage')
);

const HomePage = lazy(() => import('./home/HomePage'));

const PlayersPage = lazy(() => import('./members/PlayersPage'));
const ClubsPage = lazy(() => import('./members/ClubsPage'));

const AboutPage = lazy(() => import('./organization/AboutPage'));
const InformationPage = lazy(() => import('./organization/InformationPage'));

const SchedulePage = lazy(() => import('./competitions/SchedulePage'));
const TournamentsPage = lazy(() => import('./competitions/TournamentsPage'));
const SeriesPage = lazy(() => import('./competitions/SeriesPage'));

const PlayerStatsPage = lazy(() => import('./statistics/PlayerStatsPage'));
const ClubStatsPage = lazy(() => import('./statistics/ClubStatsPage'));
const TournamentStatsPage = lazy(() =>
  import('./statistics/TournamentStatsPage')
);
const SeriesStatsPage = lazy(() => import('./statistics/SeriesStatsPage'));
const EloStatsPage = lazy(() => import('./statistics/EloStatsPage'));
const PrStatsPage = lazy(() => import('./statistics/PrStatsPage'));

const MarketPage = lazy(() => import('./market/MarketPage'));

const EschoolPositionsPage = lazy(() =>
  import('./eschool/EschoolPositionsPage')
);
const EarticlesPage = lazy(() => import('./eschool/EarticlesPage'));
const EvideosPage = lazy(() => import('./eschool/EvideosPage'));

const MgalleryPage = lazy(() => import('./media/MgalleryPage'));
const MarticlesPage = lazy(() => import('./media/MarticlesPage'));

const LoginPage = lazy(() => import('./login/LoginPage'));
const RegisterPage = lazy(() => import('./register/RegisterPage'));

const AdminPage = lazy(() => import('./admin/AdminPage'));
const DirectorTournamentPage = lazy(() =>
  import('./director/DirectorTournamentPage')
);
const DirectorSeriesPage = lazy(() => import('./director/DirectorSeriesPage'));
const DelegatePage = lazy(() => import('./delegate/DelegatePage'));
const CrudPage = lazy(() => import('./crud/CrudPage'));

const DdPage = lazy(() => import('./secret/dd/DdPage'));
const QzPage = lazy(() => import('./secret/qz/QzPage'));
const FreakPage = lazy(() => import('./secret/freak/FreakPage'));
const WgPage = lazy(() => import('./secret/wg/WgPage'));
const XgPage = lazy(() => import('./secret/xg/XgPage'));

class AppRoutes extends Component {
  static contextType = GlobalContext;

  render() {
    return (
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route exact path='/lockscreen' component={LockScreen} />
          {this.props.lock &&
            (localStorage.getItem('unlocked') || <Redirect to='/lockscreen' />)}

          <Route exact path='/user/:userId' component={PlayerProfilePage} />
          <Route exact path='/club/:clubId' component={ClubProfilePage} />
          <Route
            exact
            path='/series/:seriesId/:tab?'
            component={SeriesInfoPage}
          />
          <Route
            exact
            path='/tournament/:tournamentId/:tab?/:round?'
            component={TournamentInfoPage}
          />

          <Route exact path='/home' component={HomePage} />

          {/*<Route exact path='/about' component={AboutPage} />
          <Route exact path='/information' component={InformationPage} />
          */}

          <Route exact path='/members/players' component={PlayersPage} />
          <Route exact path='/members/clubs' component={ClubsPage} />

          <Route exact path='/competitions/schedule' component={SchedulePage} />
          <Route
            exact
            path='/competitions/tournaments'
            component={TournamentsPage}
          />
          <Route exact path='/competitions/series' component={SeriesPage} />

          <Route exact path='/statistics/players' component={PlayerStatsPage} />
          <Route exact path='/statistics/clubs' component={ClubStatsPage} />
          <Route
            exact
            path='/statistics/tournaments'
            component={TournamentStatsPage}
          />
          <Route exact path='/statistics/series' component={SeriesStatsPage} />
          <Route exact path='/statistics/elo' component={EloStatsPage} />
          <Route exact path='/statistics/pr' component={PrStatsPage} />

          <Route exact path='/market' component={MarketPage} />

          <Route
            exact
            path='/eschool/positions'
            component={EschoolPositionsPage}
          />
          <Route exact path='/eschool/articles' component={EarticlesPage} />
          <Route exact path='/eschool/videos' component={EvideosPage} />

          <Route exact path='/media/articles' component={MarticlesPage} />
          <Route exact path='/media/gallery' component={MgalleryPage} />

          <Route exact path='/contact' component={HomePage} />

          <Route exact path='/account/login' component={LoginPage} />
          <Route
            exact
            path='/account/register/:clubUrlName?'
            component={RegisterPage}
          />
          {this.context.user && (
            <Route exact path='/account/profile'>
              <Redirect to={'/user/' + this.context.user._id} />
            </Route>
          )}
          <Route
            exact
            path='/account/superadmin'
            render={(props) => (
              <CrudPage {...props} {...cc.SUPERADMIN_CONFIG} />
            )}
          />
          <Route
            exact
            path='/account/admin'
            render={(props) => <AdminPage {...props} {...cc.ADMIN_CONFIG} />}
          />
          <Route
            exact
            path='/account/tdirector/:tournamentId?'
            render={(props) => (
              <DirectorTournamentPage {...props} {...cc.DIRECTOR_CONFIG} />
            )}
          />
          <Route
            exact
            path='/account/sdirector'
            render={(props) => (
              <DirectorSeriesPage {...props} {...cc.DIRECTOR_SERIES_CONFIG} />
            )}
          />
          <Route
            exact
            path='/account/delegate'
            render={(props) => (
              <DelegatePage {...props} {...cc.DELEGATE_CONFIG} />
            )}
          />
          {cc.CRUD_CONFIGS.map((crud, index) => (
            <Route
              key={index}
              exact
              path={'/account/' + crud.name}
              render={(props) => <CrudPage {...props} {...crud} />}
            />
          ))}

          <Route exact path='/secret/dd' component={DdPage} />
          <Route
            exact
            path='/secret/cb/:xgid?/:filter?'
            render={(props) => <QzPage {...props} cube pathParticle='cb' />}
          />
          <Route
            exact
            path='/secret/ck/:xgid?/:filter?'
            render={(props) => <QzPage {...props} checker pathParticle='ck' />}
          />
          <Route exact path='/secret/freak' component={FreakPage} />
          <Route exact path='/secret/wg' component={WgPage} />
          <Route exact path='/secret/xg' component={XgPage} />

          <Redirect to='/home' />
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;
